<template>
  <div class="brisk-img-box">
    <el-image :src="require('@/assets/images/404.png')"></el-image>
  </div>
</template>

<script>
import {onMounted} from "vue";

export default {
  name: "notFoundPage",
  setup(){
    onMounted(()=>{
      console.log('notFoundPage')
    })
    return {
    }
  }
}
</script>

<style lang="scss" scoped>
.brisk-img-box {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
</style>