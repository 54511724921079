<template>
  <div style="margin-left: 10px;margin-right: 10px">
    <div class="" style="font-size: 20px;font-weight: 500; text-align:center">
      用户协议及隐私政策
    </div>
    <div style="font-size: 16px;white-space: pre-wrap;margin-left: 10px;margin-right: 10px">
       {{`
软件名称：Homey寓健身

Homey寓健身APP非常重视用户隐私政策并严格遵守相关的法律规定。请您仔细阅读《用户协议及隐私政策》后再继续使用。如果您继续使用我们的服务，表示您已经充分阅读和理解我们协议的全部内容。
Homey寓健身尊重并保护所有使用服务用户的个人隐私权。为了给您提供更准确、更优质的服务，Homey寓健身APP会按照本隐私权政策的规定使用和披露您的个人信息。除本隐私权政策另有规定外，在未征得您事先许可的情况下，Homey寓健身APP不会将这些信息对外披露或向第三方提供。Homey寓健身APP会不时更新本隐私权政策。您在同意Homey寓健身APP服务使用协议之时，即视为您已经同意本隐私权政策全部内容。

1.适用范围
(a)在您注册Homey寓健身APP帐号时，您根据APP要求提供的个人注册信息；其中特别说明需要提供个人的敏感信息的目的，如提供姓名和身份证件号码将用于Homey寓健身会员资料的匹配与绑定；
(b)在您使用Homey寓健身APP网络服务，或访问Homey寓健身网页时，Homey寓健身APP自动接收并记录您的浏览器和计算机上的信息（其中，我们可能会在应用后台运行期间收集MAC地址等其他设备标识符进行风险校验），来综合判断APP异常登录和账号风险，提高账号的安全性，包括但不限于您的IP地址、浏览器的类型、使用的语言、访问日期和时间、软硬件特征信息及您需求的网页记录等数据；收集此信息用于确保您使用帐号的安全，有助于防范您的帐号被盗用或他人冒用。
(c)Homey寓健身APP通过合法途径从商业伙伴处取得的用户个人数据；
(d)Homey寓健身APP严禁用户发布不良信息，如裸露、色情和亵渎内容，发布的内容我们会进行审核，一经发现不良信息，会禁用该用户的所有权限。
(e)我们的产品和服务可能包括第三方的产品和服务，以及第三方网站的链接在第三方向您提供产品和服务时，可能会收集您的信息。详见以下SDK对应的隐私政策链接：
1）百度统计SDK
使用目的：统计分析
涉及的个人信息类型：设备识别信息（IMEI/Mac/androidID/IDFA/OPENUDID/GUID、SIM卡IMSI信息）、WLAN接入点（如SSID、BSSID）
处理方式：通过去标识化、加密传输和处理的安全处理方式
使用范围：用户浏览信息时
第三方名称：北京百度网讯科技有限公司
隐私政策：https://tongji.baidu.com/web/help/article?id=330&type=0

2）极光SDK
使用目的：推送信息
涉及的个人信息类型：设备信息（如IMEI/IMSI、android ID）
处理方式：通过去标识化、加密传输和处理的安全处理方式
使用范围：推送通知消息
自启动和关联启动说明：为确保本应用处于关闭或后台运行状态下可正常接收到客户端推送的广播信息，本应用须使用(自启动)能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为，是因实现功能及服务所必要的。
当您打开Homey寓健身APP内容类推送消息，在征得您的明确同意后，会跳转Homey寓健身APP打开相关内容。在未征得您同意的情况下，则不会有关联启动。
第三方名称：深圳市和讯华谷信息技术有限公司
隐私政策：https://www.jiguang.cn/license/privacy

3）支付宝SDK
使用目的：帮助用户在应用内使用支付宝
涉及的个人信息类型：设备识别信息（AndroidID如IMEI/IMSI、IOS如SIM卡序列号/MAC地址）、设备基础信息、WLAN接入点（如SSID、BSSID）、网络信息
处理方式：通过去标识化、加密传输和处理的安全处理方式
使用范围：用户使用支付宝支付时
第三方名称：支付宝（中国）网络技术有限公司
隐私政策：https://opendocs.alipay.com/open/54

4）微信Open SDK
使用目的：支持微信登录和分享、支持微信支付
涉及的个人信息类型：设备识别信息
处理方式：通过去标识化、加密传输和处理的安全处理方式
使用范围：用户微信支付、登录、分享
第三方名称：深圳市腾讯计算机系统有限公司、财付通支付科技有限公司
隐私政策：https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8

2.信息使用
(a)Homey寓健身APP不会向任何无关第三方提供、出售、出租、分享或交易您的个人登录信息。如果我们存储发生维修或升级，我们会事先发出推送消息来通知您，请您提前允许Homey寓健身APP消息通知；
(b)Homey寓健身APP亦不允许任何第三方以任何手段收集、编辑、出售或者无偿传播您的个人信息。任何Homey寓健身APP平台用户如从事上述活动，一经发现，Homey寓健身APP有权立即终止与该用户的服务协议；
(c)Homey寓健身APP会读取您的软件安装列表
目的: 存储为服务日志信息。当我们的系统发生故障时，我们会记录和分析系统故障时产生的信息，优化我们的服务。同时我们根据您是否安装微信支付宝，向您提供便捷的跳转付款服务。并根据您的软件列表是否可能有木马软件等，检测您付款环境是否安全;
方式：当您使用我们的服务时，系统从您手机读取。如果您不希望提供此数据，可以在手机系统设置--应用权限，关闭此项权限。


3.信息披露
在如下情况下，Homey寓健身APP将依据您的个人意愿或法律的规定全部或部分的披露您的个人信息：
(a)未经您事先同意，我们不会向第三方披露；
(b)为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；
(c)根据法律的有关规定，或者行政或司法机构的要求，向第三方或者行政、司法机构披露；
(d)如您出现违反中国有关法律、法规或者Homey寓健身APP服务协议或相关规则的情况，需要向第三方披露；
(e)如您是适格的知识产权投诉人并已提起投诉，应被投诉人要求，向被投诉人披露，以便双方处理可能的权利纠纷；

4.信息存储和交换
Homey寓健身APP收集的有关您的信息和资料将保存在Homey寓健身APP及（或）其关联公司的服务器上，这些信息和资料可能传送至您所在国家、地区或Homey寓健身APP收集信息和资料所在地的境外并在境外被访问、存储和展示。

5. Cookie的使用
(a)在您未拒绝接受cookies的情况下，Homey寓健身APP会在您的计算机上设定或取用cookies，以便您能登录或使用依赖于cookies的Homey寓健身APP平台服务或功能。Homey寓健身APP使用cookies可为您提供更加周到的个性化服务，包括推广服务；
(b)您有权选择接受或拒绝接受cookies。您可以通过修改浏览器设置的方式拒绝接受cookies。但如果您选择拒绝接受cookies，则您可能无法登录或使用依赖于cookies的Homey寓健身APP网络服务或功能；
(c)通过Homey寓健身APP所设cookies所取得的有关信息，将适用本政策。

6.信息安全
(a)Homey寓健身APP帐号均有安全保护功能，请妥善保管您的用户名及密码信息。Homey寓健身APP将通过对用户密码进行加密等安全措施确保您的信息不丢失，不被滥用和变造。尽管有前述安全措施，但同时也请您注意在信息网络上不存在“完善的安全措施”；。

(b)如您发现自己的个人信息泄密，尤其是Homey寓健身APP用户名及密码发生泄露，请您立即联络Homey寓健身客服，以便我们采取相应措施。
(c)如您认为您的个人信息安全在Homey寓健身APP有任何问题，请通过Homey寓健身客服进行投诉和举报。
Homey寓健身客服的联系方式如下：
电话：(021) 6040 9792

(d)用户可以通过注销帐户的形式，清除在Homey寓健身APP的所注册的用户信息，以及该APP用户在APP中产生的所有内容。注销APP帐户，不会影响会员本人在Homey寓健身房持有的合同与服务。
7.本隐私政策的更改
(a)如果决定更改隐私政策，我们会在本政策中、本公司网站中以及我们认为适当的位置发布这些更改，以便您了解我们如何收集、使用您的个人信息，哪些人可以访问这些信息，以及在什么情况下我们会透露这些信息；
(b)本公司保留随时修改本政策的权利，因此请经常查看。如对本政策作出重大更改，本公司会通过网站和APP公告通知的形式告知。

8.积分使用规则：
用户可在积分专区-我的积分-积分明细中查看积分的收入和消耗明细。
积分兑换：
用户通过积分兑换积分商城实物商品，若发生退货，系统将按照用户申请的退货商品，退还对应扣除的积分，前述积分将于7个工作日内直接退回到用户的积分账户（“积分专区-我的积分-积分明细”查收）
积分有效期：从发放开始至本年年底有效，所有积分将于每年12月31日24时清零。
其他注意事项：
积分是会员享受对应优惠/福利活动等的凭证，不构成用户资产，不具备现金价值，不可转让和折现。
若出现违规刷积分行为（包括但不限于恶意刷取积分、刷取奖励等），我们有权扣回积分（包括已兑换、已使用部分），并取消通过积分兑换的礼品订单。
    为不断完善服务增强用户体验，我们将不时更新积分服务的内容与规则，更新后的内容与规则将于公布之日起生效。若用户继续使用积分服务的，即表示同意更新后的内容与规则；若用户不同意更新后的内容与规则，有权立即停止相关使用。如更新的内容与规则涉及用户的主要权利或责任，我们将会以公告、客户端通知等方式进行公示，用户亦可在本页面查阅最新版本的内容。
积分规则解释权归Homey寓健身所有
9.自启动和关联启动说明
(a)为确保本应用处于关闭或后台运行状态下可正常接收到客户端推送的广播信息，本应用须使用(自启 动)能力，将存在一定频率通过系统发送广播唤醒本应用自启动或关联启动行为，是因实现功能及服务所必要的
(b)当您打开Homey寓健身APP内容类推送消息，在征得您的明确同意后，会跳转Homey寓健身APP打开相关内容。在未征得您同意的情况下，则不会有关联启动。
感谢您花时间了解我们的隐私政策！我们将尽全力保护您的个人信息和合法权益，再次感谢您的信任！
举报电话：(021) 6040 9792

`}}
    </div>
  </div>
</template>

<script>


export default {
  name: "mainPage",
  setup() {
    return {
    }
  }
}
</script>

<style lang="scss">

</style>